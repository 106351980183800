import ajax from "@/utils/ajax.js";

/////////////////////财务////////////////////
/**
 * 获取财务账号列表
 * @param {*} params
 * @returns
 */
export function getAccountList(params) {
  return ajax.post("/FinancialAccount/getList", params);
}

