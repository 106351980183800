import ajax from '@/utils/ajax.js'

/**
 * 获取收银软件列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/OfflineCashRegister/getList',params)
}
